(function () {
	'use strict';

	angular
		.module('app')
		.factory('trello', [
			'trelloConfig',
			'seedcodeCalendar',
			'calendarIO',
			'fullCalendarBridge',
			'daybackIO',
			'utilities',
			'manageFetch',
			trello,
		]);

	function trello(
		trelloConfig,
		seedcodeCalendar,
		calendarIO,
		fullCalendarBridge,
		daybackIO,
		utilities,
		manageFetch
	) {
		//Create our connection for google services
		var connection = createConnection();
		var eventEditQueue = {};
		var primarySource;
		var primarySourceTemplate;

		trelloConnect.setErrorReporter(errorReporter);

		return {
			getConfig: getConfig,
			getFieldMap: getFieldMap,
			getUnusedMap: getUnusedMap,
			getAllowHTMLMap: getAllowHTMLMap,
			getHiddenFieldMap: getHiddenFieldMap,
			getReadOnlyFieldMap: getReadOnlyFieldMap,
			getAllowTextFieldMap: getAllowTextFieldMap,
			getEventEditQueue: getEventEditQueue,
			auth: auth,
			switchAccount: switchAccount,
			deauthorize: deauthorize,
			getSchedules: getSchedules,
			changeScheduleName: changeScheduleName,
			changeScheduleColor: changeScheduleColor,
			disableSchedule: disableSchedule,
			getUnscheduled: getUnscheduled,
			getEvents: getEvents,
			editEvent: editEvent,
			deleteEvent: deleteEvent,
			assignColor: assignColor,
			deleteSourceEvent: deleteSourceEvent,
			updateSourceEvent: updateSourceEvent,
			onSignOut: onSignOut,
		};

		function getConfig() {
			return trelloConfig.config();
		}

		function getFieldMap() {
			return trelloConfig.fieldMap();
		}

		function getUnusedMap() {
			return trelloConfig.unusedMap();
		}

		function getAllowHTMLMap() {
			return trelloConfig.allowHTMLMap();
		}

		function getHiddenFieldMap() {
			return trelloConfig.hiddenFieldMap();
		}

		function getReadOnlyFieldMap() {
			return trelloConfig.readOnlyFieldMap();
		}

		function getAllowTextFieldMap() {
			return false;
		}

		function getEventEditQueue() {
			return eventEditQueue;
		}

		function createConnection() {
			var connection = trelloConfig.apiConnection();

			trelloConnect.settings.config.client_id = connection.clientID;
			trelloConnect.calendarApiKey = connection.calendarApiKey;
			trelloConnect.settings.config.scope = connection.accessScope;

			return connection;
		}

		function errorReporter(
			status,
			additionalMessage,
			preventDeauth,
			callback
		) {
			var config = seedcodeCalendar.get('config');

			var message = 'There was a problem communicating with Trello.';

			if (config.passthroughEditErrors) {
				return;
			}

			if (additionalMessage) {
				message += ' ' + additionalMessage;
			}

			if (!preventDeauth && (status === 401 || status === 403)) {
				message += ' - ' + 'please re-authorize DayBack.';
				if (primarySourceTemplate)
					deauthorize(callback, false, primarySourceTemplate);
			}
			if (!config.suppressEditEventMessages) {
				utilities.showMessage(message, 0, 8000, 'error');
			}
		}

		function updateSourceEvent(
			event,
			revertObject,
			revertFunc,
			changes,
			callback
		) {
			editEvent(
				event,
				revertObject,
				revertFunc,
				changes,
				null,
				processResult,
				event.schedule,
				event.recurringEventID
			);
			function processResult(data) {
				callback(event);
			}
		}

		function deleteSourceEvent(event, callback) {
			var calendarId = event.schedule.id;
			gBk.getEvent(
				calendarId,
				event.recurringEventID,
				null,
				deleteSourceEvent
			);
			function deleteSourceEvent(data) {
				gBk.deleteEvent(calendarId, data.id, processDelete);
			}
			function processDelete(data) {
				if (data) {
					message =
						'There was an error deleting the event: ' +
						data.error.message;
					utilities.showModal(
						'Operation Failed',
						message,
						null,
						null,
						'Return To Event',
						confirmError
					);
					return;
				}
				callback();
			}
			function confirmError() {
				return;
			}
		}

		function auth(callback, source, statusOnly, forceConsent) {
			var user = daybackIO.getUser();
			if (!source) {
				source = primarySource;
			}
			trelloConnect.auth(
				user.id,
				source.id,
				statusOnly,
				forceConsent,
				connection.authIsRedirect,
				connection.authRedirectFunction,
				callback
			);
		}

		function switchAccount(callback, sourceTemplate) {
			auth(
				function (authSuccess) {
					if (!authSuccess) {
						return;
					}
					deauthorize(
						function () {
							sourceTemplate.authFunction(callback);
						},
						true,
						sourceTemplate
					);
				},
				null,
				false,
				true
			);
		}

		function deauthorize(callback, saveToken, sourceTemplate) {
			var user = daybackIO.getUser();
			var source = primarySource;
			var schedules = seedcodeCalendar.get('schedules');
			var calendarElement = seedcodeCalendar.get('element');
			var eventSources = seedcodeCalendar.get('eventSources');

			var retainedSchedules = [];
			var removedSchedules = [];
			trelloConnect.deauthorize(
				user.id,
				source.id,
				saveToken,
				function () {
					// Remove auth status
					sourceTemplate.status.authed = false;

					// Only try to update calendar if it exists - Not used when initiate from settings
					if (seedcodeCalendar.get('view')) {
						// Remove Schedules and events
						for (var i = 0; i < schedules.length; i++) {
							if (schedules[i].sourceID !== source.id) {
								retainedSchedules.push(schedules[i]);
							} else {
								removedSchedules.push(schedules[i]);
							}
						}

						seedcodeCalendar.init('schedules', retainedSchedules);

						for (var i = 0; i < removedSchedules.length; i++) {
							// Remove event sources from fullcalendar
							calendarElement.fullCalendar(
								'removeEventSource',
								removedSchedules[i].source,
								removedSchedules[i].id
							);

							//Remove event source from eventSourcesObject
							if (
								eventSources.indexOf(
									removedSchedules[i].source
								) > -1
							) {
								eventSources.splice(
									eventSources.indexOf(
										removedSchedules[i].source
									),
									1
								);
							}
						}

						// Remove orphanes
						var events =
							calendarElement.fullCalendar('clientEvents');
						for (var i = 0; i < events.length; i++) {
							if (events[i].schedule.sourceID === source.id) {
								calendarElement.fullCalendar(
									'removeEvents',
									events[i]
								);
							}
						}
					}

					if (callback) {
						callback();
					}
				}
			);
		}

		function getSchedules(callback, sourceTemplate) {
			var config = seedcodeCalendar.get('config');
			var sources = seedcodeCalendar.get('sources');
			var userProfile = gBk.getUserProfile();
			var source;

			primarySourceTemplate = sourceTemplate;

			//Locate our dayback source data for google calendar
			for (var i = 0; i < sources.length; i++) {
				if (sources[i].sourceTypeID === sourceTemplate.id) {
					source = sources[i];
					if (userProfile) {
						source.name = userProfile.name;
						source.email = userProfile.email;
					}
					//Run our authorization
					primarySource = source;

					auth(authCallBack, source, true);
					break;
				}
			}

			return [];

			function authCallBack(auth) {
				if (auth) {
					getCalendarListStore();
				} else {
					callback(false, sourceTemplate);
				}
				sourceTemplate.status.authed = auth;
			}

			function getCalendarListStore() {
				processCalendarListStore({target: {result: null}});
				// return;
				// dataStore.get('calendars', sourceTemplate.id, processCalendarListStore)
			}
			function processCalendarListStore(e) {
				var options = {};
				trelloConnect.calendarList(calendarListCallBack, options);
				function calendarListCallBack(calendarList) {
					// If no source is specified or no calendars are returned
					if (!source || !calendarList) {
						callback([], sourceTemplate);
						return;
					}

					applySchedules(calendarList, source.id);
				}
			}

			function applySchedules(schedules, sourceID) {
				var scheduleID;
				//Execute our test
				//indexDBTest(sourceTemplate.id, schedules);

				//Clean schedule data
				for (var i = 0; i < schedules.length; i++) {
					//Merge source data that is missing from the returned calendar / schedule
					for (var property in source) {
						if (schedules[i][property] === undefined) {
							schedules[i][property] = source[property];
						}
					}

					schedules[i].sourceID = sourceID;

					//Add dayback source data to schedules because this is not a 1:1 source type
					schedules[i].customFields = source.customFields
						? JSON.parse(JSON.stringify(source.customFields))
						: null;
					schedules[i].customActions = source.customActions
						? JSON.parse(JSON.stringify(source.customActions))
						: null;
					schedules[i].eventActions = source.eventActions
						? JSON.parse(JSON.stringify(source.eventActions))
						: null;
					schedules[i].fieldMap = source.fieldMap
						? JSON.parse(JSON.stringify(source.fieldMap))
						: null;
					schedules[i].unusedMap = source.unusedMap
						? JSON.parse(JSON.stringify(source.unusedMap))
						: null;
					schedules[i].allowHTMLMap = source.allowHTMLMap
						? JSON.parse(JSON.stringify(source.allowHTMLMap))
						: null;
					schedules[i].labelMap = source.labelMap
						? JSON.parse(JSON.stringify(source.labelMap))
						: null;

					scheduleID = utilities.stringToID(schedules[i].id);

					if (source[scheduleID]) {
						//Append schedule specific items to already specified source items
						for (var property in source[scheduleID]) {
							if (
								schedules[i][property] &&
								(property === 'eventActions' ||
									property === 'customActions' ||
									property === 'customFields' ||
									property === 'fieldMap' ||
									property === 'unusedMap' ||
									property === 'allowHTMLMap' ||
									property === 'labelMap')
							) {
								for (var calendarProperty in source[scheduleID][
									property
								]) {
									if (
										property === 'fieldMap' ||
										property === 'unusedMap' ||
										property === 'allowHTMLMap' ||
										property === 'labelMap'
									) {
										if (
											!schedules[i][property][
												calendarProperty
											]
										) {
											schedules[i][property][
												calendarProperty
											] =
												source[scheduleID][property][
													calendarProperty
												];
										}
									} else {
										schedules[i][property][
											calendarProperty
										] =
											source[scheduleID][property][
												calendarProperty
											];
									}
								}
							} else {
								schedules[i][property] =
									sourceTemplate.settings[property] &&
									!sourceTemplate.settings[property]
										.scheduleOnly &&
									source[property] !== 'disable-global'
										? source[property]
										: source[scheduleID][property];
							}
						}
					}

					//Set source specific custom field properties
					if (schedules[i].customFields) {
						for (var property in schedules[i].customFields) {
							schedules[i].customFields[property].mutateInput =
								customFieldMutateInput;
						}
					}

					calendarIO.cleanSchedule(schedules[i], sourceTemplate);
				}
				callback(schedules, sourceTemplate);
			}
		}

		function changeScheduleName(name, callback, schedule) {
			var request;
			if (schedule.editable) {
				request = {summary: name};
				gBk.updateCalendar(schedule.id, request, function (result) {
					callback(result);
				});
			} else {
				request = {summaryOverride: name};
				gBk.updateCalendarList(schedule.id, request, function (result) {
					callback(result);
				});
			}

			return name;
		}

		function changeScheduleColor(color, callback, schedule) {
			var request = {
				backgroundColor: color,
				foregroundColor: '#000000',
			};
			gBk.updateCalendarList(schedule.id, request, function (result) {
				callback(result);
			});
			return color;
		}

		function disableSchedule(scheduleID) {
			manageFetch.clear(scheduleID);
		}

		function getUnscheduled(callbackFunc, schedule) {
			const fieldMap = schedule.fieldMap;
			const calendarId = schedule.id;
			const options = {};

			var callback = manageFetch.create(
				schedule.id,
				'unscheduled',
				callbackFunc
			).confirm;

			if (!schedule.allowUnscheduled) {
				callback();
				return;
			}

			trelloConnect.eventList(
				calendarId,
				fieldMap,
				processEvents,
				options
			);

			function processEvents(result) {
				if (!result || result.error) {
					schedule.error = result.error;
					callback();
					return;
				}
				const events = result.filter((item) => {
					return !item.due && !item.start;
				});
				callback(mutateEvents(events, schedule));
			}
		}

		function getEvents(
			start,
			end,
			timezone,
			callbackFunc,
			schedule,
			options,
			eventID,
			fetchID,
			requestOverride
		) {
			const fieldMap = schedule.fieldMap;

			const calendarId = schedule.id;
			const queryStart = moment(start).format();
			const queryEnd = moment(end).format();

			const queryOptions = {
				dueBetween: `${queryStart},${queryEnd}`,
			};

			var callback = manageFetch.create(
				schedule.id,
				'event',
				callbackFunc
			).confirm;

			// Run before events fetched action
			const actionCallbacks = {
				confirm: function () {
					trelloConnect.eventList(
						calendarId,
						fieldMap,
						processEvents,
						queryOptions
					);
				},
				cancel: function () {
					callback(mutateEvents([], schedule));
				},
			};

			const actionResult = calendarIO.runEventActions(
				'beforeEventsFetched',
				{schedule: schedule},
				true,
				actionCallbacks,
				null
			);

			if (!actionResult) {
				return;
			}

			trelloConnect.eventList(
				calendarId,
				fieldMap,
				processEvents,
				queryOptions
			);
			function processEvents(result) {
				if (!result || result.error) {
					schedule.error = result.error;
					callback();
					return;
				}
				//callback is the built in full calendar event callback. This will write the events we just got to the view.
				callback(mutateEvents(result, schedule));
			}
		}

		function customFieldMutateInput(item, fieldDefinition) {
			var result;
			if (fieldDefinition.formatas === 'select') {
				if (!item) {
					result = [];
				} else if (Array.isArray(item)) {
					result = item;
				} else {
					try {
						result = JSON.parse(item);
						if (!Array.isArray(result)) {
							result = [item];
						}
					} catch (error) {
						result = [item];
					}
				}
				calendarIO.replaceWithNoFilterValue(result);
			} else {
				result = item;
			}
			return result;
		}

		function customFieldMutateOutput(item, customField, schedule) {
			if (customField && customField.formatas === 'date') {
				return item ? moment(item).format(schedule.fileDateFormat) : '';
			} else if (customField && customField.formatas === 'timestamp') {
				return item
					? moment(item).format(schedule.fileTimestampFormat)
					: '';
			} else if (Array.isArray(item)) {
				calendarIO.removeNoFilterValue(item);
				return JSON.stringify(item);
			} else {
				return item;
			}
		}

		function getRRuleFreq(rule) {
			var afterFreq = rule.split('FREQ=');
			if (!afterFreq.length) {
				return afterFreq[0].split(';')[0];
			} else {
				return afterFreq[1].split(';')[0];
			}
		}

		function rruleToDates(
			event,
			recurrence,
			recurrenceFrequency,
			rangeStart,
			rangeEnd,
			dayRange
		) {
			var recurrenceRule;
			var recurrenceType;
			var recurrenceStartDiff;

			var ruleFromString;

			// alert(rrule.rrulestr('DTSTART:20120201T023000Z\nRRULE:FREQ=MONTHLY;COUNT=5'));

			// recurrenceType = recurrenceFrequency === 'DAILY' ? 'DAY' : recurrenceFrequency.slice(0, -2);

			// recurrenceStartDiff = moment(event.start).diff(moment(rangeStart).subtract(1, 'day'), recurrenceType);

			recurrenceRule = recurrence;
			recurrenceRule +=
				'\nDTSTART:' +
				moment(event.start)
					.hours(0)
					.minutes(0)
					.seconds(0)
					.utc()
					.format('YYYYMMDDTHHmmss[Z]'); // Subtract a day to make inclusive
			// recurrenceRule += ';DTSTART=' + '20191028T190000Z';
			// recurrenceRule += ';UNTIL=' + moment(rangeEnd).utc().format('YYYYMMDD');
			// ruleFromString = rrule.RRule.fromString(recurrenceRule);

			ruleFromString = rrule.rrulestr(recurrenceRule);
			// Return array of dates
			return ruleFromString.between(
				rangeStart.toDate(),
				rangeEnd.toDate(),
				'inc=true'
			);
		}

		function mutateEvents(events, schedule) {
			const hookResult = calendarIO.mutateHook();
			if (!hookResult) {
				return [];
			}

			var customFields = schedule.customFields;
			var fieldMap = schedule.fieldMap;
			var output = [];
			var propertyValue;

			for (var i = 0; i < events.length; i++) {
				const event = {};
				for (var property in fieldMap) {
					propertyValue = events[i][fieldMap[property]];
					event[property] = propertyValue;
				}
				output.push(mutate(event));
			}

			return output;
			function mutate(event) {
				const members = trelloConnect.getMembers();
				event.eventSource = schedule.id;
				event.schedule = schedule;
				event.color = schedule.backgroundColor;
				event.textColor = schedule.foregroundColor;
				//field specific transformations

				//convert status to array
				event.status = event.status === '' ? [] : [event.status];

				//convert resource to array
				event.resource =
					!event.resource || event.resource === ''
						? []
						: event.resource;

				// //convert contact to array
				event.contactName =
					!event.contactName || event.contactName === ''
						? []
						: event.contactName.split('\n');
				event.contactID =
					!event.contactID || event.contactID === ''
						? []
						: event.contactID.split('\n');

				// //convert project to array
				event.projectName =
					!event.projectName || event.projectName === ''
						? []
						: event.projectName.split('\n');
				event.projectID =
					!event.projectID || event.projectID === ''
						? []
						: event.projectID.split('\n');
				if (!event.start && !event.end) {
					event.unscheduled = true;
				} else if (!event.start && event.end) {
					event.start = event.end;
				} else if (!event.end && event.start) {
					event.end = event.start;
				}

				// Apply timezone if necessary
				event.start = $.fullCalendar.createTimezoneTime(
					event.start,
					event.allDay
				);
				event.end = $.fullCalendar.createTimezoneTime(
					event.end,
					event.allDay
				);

				// Convert member id's to names
				for (let i = 0; i < event.resource.length; i++) {
					for (const member of members) {
						if (member.id === event.resource[i]) {
							event.resource[i] = member.fullName;
							break;
						}
					}
				}

				// Convert list id's to names
				for (let i = 0; i < event.status.length; i++) {
					for (const list of schedule.lists) {
						if (list.id === event.status[i]) {
							event.status[i] = list.name;
							break;
						}
					}
				}

				//return mulated event
				return calendarIO.cleanEvent(event, true);
			}
		}

		// Process title calculation
		function processTitle(schedule) {
			var fieldMap = schedule.fieldMap;
			//clean up entry
			title = title
				.replace(/[\n\r]/g, '')
				.replace(/, +/g, ',')
				.replace(/ +,/g, ',');

			var re;
			var titleDataArray = [];
			var resultArray = [];
			var titleArray = title ? title.split(',') : [];
			var populated;

			for (var i = 0; i < titleArray.length; i++) {
				populated = false;
				for (var field in fieldMap) {
					var thisField = fields[field];
					re = new RegExp(thisField, 'g');
					if (titleArray[i].indexOf(thisField) !== -1) {
						fieldResult = formatDateTime(
							utilities.htmlEscape(
								getFieldValue(thisField, event)
							)
						);
						if (fieldResult) {
							populated = true;
							titleDataArray[i] = titleArray[i].replace(
								re,
								fieldResult
							);
						} else {
							titleDataArray[i] = titleArray[i].replace(re, '');
						}
					}
				}
				if (populated) {
					resultArray.push(addMatch(titleDataArray[i]));
				}
			}
		}

		//Edit Events
		function editEvent(
			event,
			revertObject,
			revertFunc,
			changes,
			editID,
			callback,
			schedule,
			sourceEventId
		) {
			const config = seedcodeCalendar.get('config');
			const customFields = schedule.customFields;
			const allDay = changes.hasOwnProperty('allDay')
				? changes.allDay
				: event.allDay;
			const fieldMap = schedule.fieldMap;
			const editObj = {};
			const additionalEditProperties = {};
			const id = event.eventID;

			let listID;

			for (var property in fieldMap) {
				//Don't send unused / unmapped items to edit object
				if (!fieldMap[property] || schedule.unusedMap?.[property]) {
					continue;
				}

				//If the field is mapped and it has a change property, or if it is a new event write all non empty fields
				if (
					property !== 'eventID' &&
					property !== 'title' &&
					changes.hasOwnProperty(property)
				) {
					//format start and stop as needed
					//don't populate these if we're doing a source event
					if (
						(property === 'start' || property === 'end') &&
						!sourceEventId
					) {
						editObj[fieldMap[property]] = changes.allDay
							? moment(changes[property].format('YYYY-MM-DD'))
							: moment(changes[property]);
					} else if (
						property === 'resource' ||
						property === 'status'
					) {
						editObj[fieldMap[property]] = changes[property].slice(); //Using slice here to clone the array
					} else if (property === 'tags') {
						editObj[fieldMap[property]] =
							calendarIO.packageTagsOutput(event[property]);
					} else if (property === 'eventSource') {
						//don't do anything as we don't want to add eventSource to editObj
					}
					// //Join arrays
					else if (Array.isArray(event[property])) {
						editObj[fieldMap[property]] = changes[property].slice(); //Use slice here to clone the array rather than using a reference
					} else {
						editObj[fieldMap[property]] = changes[property];
					}
				}
			}

			// Normalize end date for new events
			if (
				!event.eventID &&
				editObj[fieldMap.end] &&
				editObj[fieldMap.allDay]
			) {
				editObj[fieldMap.end].subtract(1, 'days');
			}
			// Apply timezone offsets and stringify
			if (editObj[fieldMap.start]) {
				editObj[fieldMap.start] = $.fullCalendar
					.timezoneTimeToLocalTime(editObj[fieldMap.start], allDay)
					.format();
			}
			if (editObj[fieldMap.end]) {
				editObj[fieldMap.end] = $.fullCalendar
					.timezoneTimeToLocalTime(editObj[fieldMap.end], allDay)
					.format();
			}

			// Switch status name to id's
			if (editObj[fieldMap.status]) {
				let matched = false;
				for (let i = 0; i < editObj[fieldMap.status].length; i++) {
					for (const list of schedule.lists) {
						if (list.name === editObj[fieldMap.status][i]) {
							editObj[fieldMap.status] = list.id;
							listID = list.id;
							matched = true;
							break;
						}
					}
					if (matched) {
						break;
					}
				}

				if (!matched) {
					listID = event.idList;
					delete editObj[fieldMap.status];
				}
			}

			if (!listID) {
				listID = schedule.lists[0].id;
			}

			// //Check if we should be changing the calendar
			// if (IdToUse && changes.eventSource) {
			// 	//Change calendar
			// 	gBk.changeCalendar(
			// 		IdToUse,
			// 		revertObject.eventSource,
			// 		changes.eventSource,
			// 		fieldMap,
			// 		changeCalendarSuccess
			// 	);
			// }
			//If not just apply our event changes
			// else {
			//Edit the event
			applyEditEvent();
			// }

			//Callback for changing the calendar
			function changeCalendarSuccess(result) {
				if (result && result.error) {
					resultCallback(result);
				} else {
					//Edit the event
					applyEditEvent();
				}
			}
			//Apply our event result (write result data)
			function applyEditEvent() {
				//if we have an id we're updating
				if (event.eventID) {
					trelloConnect.updateEvent(
						schedule.id,
						event.eventID,
						editObj,
						additionalEditProperties,
						fieldMap,
						resultCallback,
						null,
						null
					);
				} else {
					editObj.idList = listID;
					trelloConnect.createEvent(
						schedule.id,
						editObj,
						additionalEditProperties,
						fieldMap,
						resultCallback,
						null
					);
				}
			}
			function resultCallback(result) {
				var element = seedcodeCalendar.get('element');
				var eventResult;
				var aResult = !result.items ? [result] : result.items;
				var message;
				var modified;
				var domEvents;
				var newSeries;

				//If we are dragging this event currently we need to wait and see if the event has changed or not
				if (eventEditQueue.dragging) {
					window.setTimeout(function () {
						resultCallback(result);
					}, 250);
					return;
				}

				//We are editing the same event before the last save so exit the save
				if (editID && eventEditQueue[event.eventID] > editID) {
					return;
				} else {
					delete eventEditQueue[event.eventID];
				}

				//Check for errors and if we detect one revert the record
				if (result && result.error) {
					if (config.passthroughEditErrors) {
						if (callback) {
							callback(result);
						}
					} else if (config.suppressEditEventMessages) {
						dragError();
					} else if (revertFunc) {
						message =
							'There was an error saving the event and your changes will be reverted: ' +
							result.error.message;
						utilities.showModal(
							'Operation Failed',
							message,
							null,
							null,
							'Revert Changes',
							dragError
						);
					} else if (revertObject) {
						//applyEventChanges(event, revertObject); //Would revert the event but we don't want that in this case as we don't want to lose changes
						message =
							'There was an error and your event could not be saved: ' +
							result.error.message;
						utilities.showModal(
							'Operation Failed',
							message,
							'Return To Event',
							cancelError,
							'Revert Changes',
							confirmError
						);
					}
					return;
				}

				//No errors found continue with updating the event with the returned data
				aResult = mutateEvents(aResult, schedule);

				if (editObj.recurrence && editObj.recurrence.length > 0) {
					newSeries = true;
				}

				for (var i = 0; i < aResult.length; i++) {
					eventResult = aResult[i];

					eventResult.allDay = event.allDay;
					eventResult._allDay = eventResult.allDay;

					if (aResult.length === 1 || (newSeries && i === 0)) {
						// Normalize dates
						seedcodeCalendar
							.get('element')
							.fullCalendar(
								'buildEventDates',
								eventResult,
								eventResult
							);

						modified = applyEventChanges(event, eventResult);
						if (modified) {
							fullCalendarBridge.update(element, event);
						}
					} else if (newSeries) {
						seedcodeCalendar
							.get('element')
							.fullCalendar('renderEvent', eventResult);
					} else {
						for (var ii = 0; ii < domEvents.length; ii++) {
							if (eventResult.eventID === domEvents[ii].eventID) {
								//case 49365. There's a difference when fullCalendarBridge.update(element, domEvents[ii]);
								//is used that causes a change false positive on "end" when opened and closed in the popover
								//working around this by removing and re-rendering - small performance difference
								element.fullCalendar(
									'removeEvents',
									domEvents[ii]
								);
								seedcodeCalendar
									.get('element')
									.fullCalendar('renderEvent', eventResult);
							}
						}
					}
				}

				if (callback) {
					callback(event);
				}

				//Define error functions for this edit
				function dragError() {
					if (revertFunc) {
						revertFunc(null, event, result.error);
					}
				}
				function confirmError() {
					if (event.eventID) {
						applyEventChanges(event, revertObject);
						calendarIO.assignEventColor(event);
						fullCalendarBridge.update(element, event);
						if (callback) {
							callback(false);
						}
					} else {
						//Remove event from view
						fullCalendarBridge.removeEvents(element, event);
					}
				}
				function cancelError() {
					if (!event.eventStatus) {
						event.eventStatus = {};
					}

					event.eventStatus.revertObject = revertObject;

					if (event.eventStatus.isCustomAction) {
						applyEventChanges(event, revertObject);
					} else {
						event.eventStatus.showPopover = true;
						event.eventStatus.unsavedChanges = true;
						fullCalendarBridge.update(element, event);
						//Reset event status for custom action state
						event.eventStatus.isCustomAction = false;
					}
				}
			}
		}

		//Apply event changes returned from backend after an edit. Also returns wether the event was modified on the server
		function applyEventChanges(event, changes) {
			var modified;
			//Check if we need to apply a new schedule
			if (changes.schedule && event.schedule.id !== changes.schedule.id) {
				modified = true;
				event.schedule = changes.schedule;
			}
			//Loop through all properties and set those
			for (var property in changes) {
				//Check string, numbers, and booleans
				if (
					(typeof changes[property] === 'undefined' ||
						typeof changes[property] === 'string' ||
						typeof changes[property] === 'number' ||
						typeof changes[property] === 'boolean' ||
						(typeof changes[property] === 'object' &&
							changes[property] === null)) &&
					property !== 'dateStart' &&
					property !== 'dateEnd' &&
					property !== 'timeStart' &&
					property !== 'timeEnd' &&
					property !== 'start' &&
					property !== 'end'
				) {
					if (
						(event[property] === undefined ||
							event[property] === null ||
							event[property] === '') &&
						(changes[property] === undefined ||
							changes[property] === null ||
							changes[property] === '')
					) {
						// we have a null value so let's just set back to event to normalize but don't mark anything as modified
						event[property] = changes[property];
					} else if (event[property] !== changes[property]) {
						if (property !== 'eventID') {
							modified = true;
						}
						event[property] = changes[property];
					}
				}

				//Check arrays
				else if (Array.isArray(changes[property])) {
					if (
						!utilities.arraysEqual(
							event[property],
							changes[property]
						)
					) {
						modified = true;
						event[property] = changes[property];
					}
				}
				//Check moment objects
				else if (
					moment.isMoment(changes[property]) ||
					property === 'start' ||
					property === 'end'
				) {
					if (
						!moment(changes[property]).isSame(
							moment(event[property])
						)
					) {
						modified = true;
						event[property] = changes[property];
					}
				}
			}
			return modified;
		}

		//Delete Event
		function deleteEvent(event, callback, schedule) {
			trelloConnect.deleteEvent(
				schedule.id,
				event.eventID,
				processDelete
			);

			function processDelete(result) {
				var message;
				if (result?.error) {
					message =
						'There was an error deleting the event: ' +
						result.error.message;
					utilities.showModal(
						'Operation Failed',
						message,
						null,
						null,
						'Return To Event',
						confirmError
					);
					return;
				}
				calendarIO.confirmDelete(event, 0, callback);
			}
			function confirmError() {
				return;
			}
		}

		function assignColor(event) {
			var colors = trelloConfig.colors();

			if (!event.colorId) {
				return false;
			}

			for (var property in colors) {
				if (event.colorId == property) {
					event.color = colors[property].background;
					return colors[property].background;
				}
			}
		}

		function onSignOut() {
			gBk.clearTokenMemory();
		}
	}
})();
